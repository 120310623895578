<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('payment_plans_report')"
                        :isColumns="true"
                        @filter-div-status="datatable.filterStatus=$event"
                >
                    <template v-slot:columns>
                        <div class="mb-1" v-for="(column,key) in datatable.columns">
                            <b-form-checkbox
                                :id="'checkbox-'+key"
                                :name="'checkbox-'+key"
                                :value="false"
                                :unchecked-value="true"
                                v-model="column.hidden"
                                v-if="column.field!='buttons'"
                            >
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('payment_plans_report')"
                              :isColumns="true"
                              @filter-div-status="datatable.filterStatus=$event"
                >
                    <template v-slot:columns>
                        <div class="mb-1" v-for="(column,key) in datatable.columns">
                            <b-form-checkbox
                                :id="'checkbox-'+key"
                                :name="'checkbox-'+key"
                                :value="false"
                                :unchecked-value="true"
                                v-model="column.hidden"
                                v-if="column.field!='buttons'"
                            >
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </HeaderMobile>
            </template>
            <datatable-filter v-show="datatable.filterStatus" @filter="$refs.datatable.getRows()"
                              @filterClear="$refs.datatable.filterClear()"
            :exportExcel="true" @exportExcel="$refs.datatable.excel()">
                <div class="row">
                    <div class="col-9">
                        <b-row>
                        <div class="col-6 col-md-6 col-xl-6 col-xxl-6">
                            <b-form-group :label="$t('periods')">
                                <payment-periods-selectbox
                                    v-model="datatable.queryParams.filter.period_id">
                                </payment-periods-selectbox>
                            </b-form-group>
                        </div>

                        <div class="col-6 col-md-6 col-xl-6 col-xxl-6">
                            <b-form-group :label="$t('products')">
                                <payment-products-selectbox
                                    v-model="datatable.queryParams.filter.product_id">
                                </payment-products-selectbox>
                            </b-form-group>
                        </div>

                        <div class="col-6 col-md-6 col-xl-6 col-xxl-6">
                            <b-form-group :label="$t('payment_plan_types')">
                                <payment-plan-types-selectbox
                                    v-model="datatable.queryParams.filter.type">
                                </payment-plan-types-selectbox>
                            </b-form-group>
                        </div>

                        <div class="col-6 col-md-6 col-xl-6 col-xxl-6">
                            <b-form-group :label="$t('registration_type')">
                                <parameter-selectbox
                                    code="registration_types"
                                    v-model="datatable.queryParams.filter.registration_type">
                                </parameter-selectbox>
                            </b-form-group>
                        </div>

                        <div class="col-6 col-md-6 col-xl-6 col-xxl-6">
                            <b-form-group :label="$t('faculty')">
                                <faculty-selectbox
                                    v-model="datatable.queryParams.filter.faculty_code">
                                </faculty-selectbox>
                            </b-form-group>
                        </div>

                        <div class="col-6 col-md-6 col-xl-6 col-xxl-6">
                            <b-form-group :label="$t('program_level')">
                                <payment-program-level-selectbox
                                    v-model="datatable.queryParams.filter.program_level">
                                </payment-program-level-selectbox>
                            </b-form-group>
                        </div>

                        <div class="col-6 col-md-6 col-xl-6 col-xxl-6">
                            <b-form-group :label="$t('program')">
                                <program-selectbox
                                    v-model="datatable.queryParams.filter.program_code"
                                    :faculty_code="datatable.queryParams.filter.faculty_code">
                                </program-selectbox>
                            </b-form-group>
                        </div>
                        </b-row>
                    </div>
                    <div class="col-3">
                        <b-row>
                            <b-col cols="12">
                                <ValidationProvider name="number" rules="" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('student_numbers')">
                                        <lined-textarea v-model="datatable.queryParams.filter.number"
                                                        :nowrap="false"
                                                        :disabled="false"
                                                        :styles="{ height: '15em', resize: 'both' }"
                                                        :validateError="errors[0]">
                                        </lined-textarea>
                                        <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                        </b-row>
                    </div>
                </div>
            </datatable-filter>
            <datatable ref="datatable" :isLoading="datatable.isLoading"
                       :columns="datatable.columns"
                       :rows="datatable.rows"
                       :total="datatable.total"
                       :queryParams="datatable.queryParams"
                       v-show="datatable.showTable"
                       :data-function="callService"
            >
            </datatable>
        </app-layout>
    </div>
</template>
<script>
import AppLayout from "@/layouts/AppLayout";
import Header from "@/layouts/AppLayout/Header";
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile";
import {ValidationObserver, ValidationProvider} from "vee-validate";
import PaymentPeriodsSelectbox from "@/components/interactive-fields/PaymentPeriodsSelectbox";
import PaymentProductsSelectbox from "@/components/interactive-fields/PaymentProductsSelectbox";
import PaymentPlanTypesSelectbox from "@/components/interactive-fields/PaymentPlanTypesSelectbox";
import FacultySelectbox from "@/components/interactive-fields/FacultySelectbox";
import ProgramSelectbox from "@/components/interactive-fields/ProgramSelectbox";
import PaymentProgramLevelSelectbox from "@/components/interactive-fields/PaymentProgramLevelSelectbox";
import SemestersSelectbox from "@/components/interactive-fields/SemestersSelectbox";
import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox";
import PaymentPlanService from "@/services/PaymentPlanService";
import DatatableFilter from '@/components/datatable/DatatableFilter';
import Datatable from '@/components/datatable/Datatable';
import LinedTextarea from "@/components/elements/LinedTextarea";

export default {
    components: {
        AppLayout,
        Header,
        HeaderMobile,
        ValidationProvider,
        ValidationObserver,
        PaymentPeriodsSelectbox,
        PaymentProductsSelectbox,
        PaymentPlanTypesSelectbox,
        FacultySelectbox,
        ProgramSelectbox,
        PaymentProgramLevelSelectbox,
        ParameterSelectbox,
        DatatableFilter,
        Datatable,
        LinedTextarea
    },
    data() {
        return {
            createForm: {},
            datatable: {
                isLoading: false,
                columns: [
                    {
                        label: this.toUpperCase('id'),
                        field: 'id',
                        hidden: true,
                    },
                    {
                        label: this.toUpperCase('period'),
                        field: 'period',
                    },
                    {
                        label: this.toUpperCase('product_name'),
                        field: 'product_name',
                    },
                    {
                        label: this.toUpperCase('student_number'),
                        field: 'student_number',
                    },
                    {
                        label: this.toUpperCase('name'),
                        field: 'name',
                    },
                    {
                        label: this.toUpperCase('surname'),
                        field: 'surname',
                    },
                    {
                        label: this.toUpperCase('faculty'),
                        field: 'faculty_name',
                    },
                    {
                        label: this.toUpperCase('program'),
                        field: 'program_name',
                    },
                    {
                        label: this.toUpperCase('registration_academic_year'),
                        field: 'registration_academic_year',
                    },
                    {
                        label: this.toUpperCase('registration_type'),
                        field: 'registration_type',
                    },
                    {
                        label: this.toUpperCase('student_status'),
                        field: 'student_status',
                    },
                    {
                        label: this.toUpperCase('payment_status'),
                        field: 'payment_status',
                    },
                    {
                        label: this.toUpperCase('due_date'),
                        field: 'due_date',
                        formatFn: this.formatDate
                    },
                    {
                        label: this.toUpperCase('gross_price'),
                        field: 'gross_price',
                        formatFn: this.formatNumber
                    },
                    {
                        label: "Net",
                        field: 'price',
                        formatFn: this.formatNumber
                    },
                    {
                        label: "Installment Amount",
                        field: 'total_amount',
                        formatFn: this.formatNumber
                    },
                    {
                        label: this.toUpperCase('total_paid'),
                        field: 'total_paid',
                        formatFn: this.formatNumber
                    },
                    {
                        label: this.toUpperCase('total_discount'),
                        field: 'total_discount',
                        formatFn: this.formatNumber
                    },
                    {
                        label: this.toUpperCase('discount_per_credit'),
                        field: 'scholarship_per_credit',
                        formatFn: this.formatNumber
                    },
                    {
                        label: this.toUpperCase('cash_discount_rate'),
                        field: 'cash_discount_rate',
                        formatFn: this.formatNumber
                    },
                    {
                        label: this.toUpperCase('total_discount_rate'),
                        field: 'total_discount_rate',
                        formatFn: this.formatNumber
                    },
                    {
                        label: this.toUpperCase('total_refund'),
                        field: 'total_refund',
                        formatFn: this.formatNumber
                    },
                    {
                        label: this.toUpperCase('balance'),
                        field: 'balance',
                        formatFn: this.formatNumber
                    }

                ],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: false,
                queryParams: {
                    filter: {
                        type: 'registration'
                    },
                    sort: 'id',
                    page: 1,
                    limit: 20
                }
            },
        }
    },
    metaInfo() {
        return {
            title: this.$t("payment_plans_report")
        }
    },
    methods: {
        callService(config) {
            let oldValue=config.params.filter.number;
            if(config.params.filter.number){
                config.params.filter.number=config.params.filter.number.split(/\n/);
            }

            let response = PaymentPlanService.report(config)
            config.params.filter.number = oldValue;

            return response;
        },
        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage;
            this.$refs.datatable.getRows();
        },
        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        changeColumn(key) {
            this.datatable.columns[key].hidden = this.datatable.columns[key].hidden == true ? false : true;
        },
        onSortChange(params) {
            const sortType = params[0].type == 'desc' ? '-' : '';
            this.datatable.queryParams.sort = sortType + params[0].field
            this.$refs.datatable.getRows();
        },
    }
}
</script>
